import { inject, Inject, InjectionToken } from '@angular/core';
import { Locale, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const DATE_LOCALE = new InjectionToken<Locale>('Date locale');

@Inject({ providedIn: 'root' })
export class DateHelperService {
  locale = inject(DATE_LOCALE);

  numberToDate(timestamp: number, timeZoneId: string): string {
    return formatInTimeZone(new Date(timestamp), timeZoneId, 'P', { locale: this.locale });
  }

  numberToTime(timestamp: number, timeZoneId: string): string {
    return formatInTimeZone(new Date(timestamp), timeZoneId, 'p', { locale: this.locale });
  }

  combineDateAndTime(date: Date, time: string): Date {
    const [hours, minutes] = time.split(':').map(Number);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
  }

  dateTimeToStringISO(date: Date): string {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss");
  }
}
