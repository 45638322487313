import { PaymentMethod, PaymentMethodType } from '../../shared/models/payment-methods.model';
import { PaymentMethodTypeVM } from '../../shared/models/business.vm';

export function mapPaymentMethodToPaymentMethodType(paymentMethod: PaymentMethod): PaymentMethodTypeVM {
  switch (paymentMethod.type) {
    case PaymentMethodType.IN_PERSON:
      return PaymentMethodTypeVM.IN_PERSON;
    case PaymentMethodType.INVOICE:
      return PaymentMethodTypeVM.INVOICE;
    default:
      throw new Error('Invalid payment method');
  }
}
