/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { config } from 'src/environments/config';

@Injectable()
export class IntercomService {
  get Intercom() {
    return (window as any).Intercom;
  }

  boot(userId: string, userHash: string, isHandset = false) {
    this.Intercom('boot', {
      app_id: config.intercomAppId,
      user_id: userId,
      user_hash: userHash,
      alignment: 'left',
      hide_default_launcher: isHandset,
    });
  }

  showNewMessages() {
    this.Intercom('showNewMessage');
  }

  shutdown() {
    this.Intercom('shutdown');
  }

  hideLauncher() {
    this.Intercom('update', { hide_default_launcher: true });
  }

  showLauncher() {
    this.Intercom('update', { hide_default_launcher: false });
  }

  hideIntercom() {
    this.Intercom('hide');
  }

  showIntercom() {
    this.Intercom('show');
  }
}
