import { LocationVM } from './location.vm';
import { LatLng } from './latlng.model';

export interface BusinessVM {
  id: string;
  name: string;
  companyCode: string;
  companySize: string;
  countryCode: string;
  dashboardUrl: string;
  paymentMethodTypes: PaymentMethodTypeVM[];
  partnerDiscountPercent: number;
  selfInvitationEmailDomains: string[];
  emailDomainString: string;
  countryCenter: LatLng;
}

export interface AgentTemplateVM {
  templateId: string;
  name: string;
  pickupLocation: LocationVM;
  phone: string;
  note: string;
}

export enum PaymentMethodTypeVM {
  UNDEFINED = 0,
  INVOICE = 4,
  IN_PERSON = 5,
}
