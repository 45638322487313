import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRides from './rides.reducer';
import { getRideStatus, mapBookingAggregateToRideDetails } from './mappings';
import { selectBusiness } from '../../store/home.selectors';
import { dateTimeNowAddDuration } from 'src/app/shared/utils/datetime';
import { toZonedTime } from 'date-fns-tz';
import { AuthSelectors } from 'src/app/auth/store';
import { RideStatus } from '../../../shared/models/rides.vm';

export const selectRidesState = createFeatureSelector<fromRides.State>(fromRides.ridesFeatureKey);

export const selectBusinessSiteId = createSelector(AuthSelectors.selectFcUserId, userid => userid);

export const selectTableVM = createSelector(selectRidesState, state => state.tableVM);
export const selectFilter = createSelector(selectRidesState, state => state.filter);
export const selectIsLoadingRides = createSelector(selectRidesState, state => state.isLoadingRides);
export const selectIsLoadingRideDetails = createSelector(selectRidesState, state => state.isLoadingRideDetails);
export const selectPagination = createSelector(selectRidesState, state => state.pagination);
export const selectActiveTab = createSelector(selectRidesState, state => state.activeTab);

export const selectDataSourceParameters = createSelector(
  selectFilter,
  selectPagination,
  selectActiveTab,
  (dateFilter, pagination, activeTab) => ({
    text: dateFilter.text,
    pageSize: pagination.pageSize,
    pageIndex: pagination.pageIndex,
    previousPageIndex: pagination.previousPageIndex,
    activeTab: activeTab,
  }),
);

export const selectPaginatorLength = createSelector(selectRidesState, state => state.totalRowsCount);
export const selectPageIndex = createSelector(selectRidesState, state => state.pagination.pageIndex);
export const selectPageSize = createSelector(selectRidesState, state => state.pagination.pageSize);

export const selectIsNoDataContainerShown = createSelector(
  selectTableVM,
  selectFilter,
  selectIsLoadingRides,
  (tableVM, filter, isLoadingRides) => tableVM.rows?.length === 0 && filter.text.length > 0 && !isLoadingRides,
);

export const selectMapCenter = createSelector(selectBusiness, business => {
  return business ? { lat: business.countryCenter.lat, lng: business.countryCenter.lng } : null;
});

export const selectBookingAggregate = createSelector(selectRidesState, state => state.bookingAggregate);

export const selectIsCancelBookingInProgress = createSelector(
  selectRidesState,
  state => state.isCancelBookingInProgress,
);
export const selectIsSendTrackingLinkInProgress = createSelector(
  selectRidesState,
  state => state.isTrackingLinkSending,
);

export const selectIsCallDriverInProgress = createSelector(selectRidesState, state => state.isCallingDriver);

export const selectSelectedRideId = createSelector(selectRidesState, state => state.selectedRideId);

export const selectIsUpdatePickupTimeInProgress = createSelector(
  selectRidesState,
  state => state.isUpdatePickupTimeInProgress,
);
export const selectIsConfirmWithNewQuoteInProgress = createSelector(
  selectRidesState,
  state => state.isConfirmWithNewQuoteInProgress,
);

export const selectPlatformSetting = createSelector(selectRidesState, state => state.settings);

export const selectAreaId = createSelector(selectRidesState, state => state.areaId);
const selectAreas = createSelector(selectRidesState, state => state.areas);
export const selectAreaTimeZoneId = createSelector(selectAreaId, selectAreas, (areaId, areas) => {
  // @TODO check if this is correct and we don't need to throw an error if areaId is null
  return areas?.find(area => area.id === areaId)?.time_zone_id ?? 'Europe/Copenhagen';
});

export const selectRideDetails = createSelector(
  selectRidesState,
  selectAreas,
  selectMapCenter,
  (state, areas, mapCenter) =>
    state.bookingAggregate ? mapBookingAggregateToRideDetails(state.bookingAggregate, areas, mapCenter) : null,
);

export const selectMinDurationToPickupSettingValues = createSelector(selectPlatformSetting, settings =>
  settings ? settings['b2b.prebooking_min_duration_to_pickup'].values : [],
);
export const selectMaxDurationToPickupSettingValues = createSelector(selectPlatformSetting, settings =>
  settings ? settings['b2b.prebooking_max_duration_to_pickup'].values : [],
);
export const selectAllowContactDriverAfterTripDuration = createSelector(selectPlatformSetting, settings =>
  settings ? settings['b2b.allow_contact_driver_after_trip_duration'].values : [],
);

export const selectMinDurationToPickupTimeDefaultValue = createSelector(
  selectMinDurationToPickupSettingValues,
  values => (values.length > 0 ? values.find(value => value.condition === null)?.value : null),
);

export const selectMaxDurationToPickupTimeDefaultValue = createSelector(
  selectMaxDurationToPickupSettingValues,
  values => (values.length > 0 ? values.find(value => value.condition === null)?.value : null),
);

export const selectAllowContactDriverAfterTripDurationDefaultValue = createSelector(
  selectAllowContactDriverAfterTripDuration,
  values => (values.length > 0 ? values.find(value => value.condition === null)?.value : null),
);

export const selectMinDurationToPickupTimeAreaValue = createSelector(
  selectMinDurationToPickupSettingValues,
  selectAreaId,
  (values, areaId) =>
    values.length > 0 && areaId !== null ? values.find(value => value.condition?.value === areaId)?.value : null,
);

export const selectMaxDurationToPickupTimeAreaValue = createSelector(
  selectMaxDurationToPickupSettingValues,
  selectAreaId,
  (values, areaId) => {
    return values.length > 0 && areaId !== null ? values.find(value => value.condition?.value === areaId)?.value : null;
  },
);

export const selectAllowContactDriverAfterTripDurationAreaValue = createSelector(
  selectAllowContactDriverAfterTripDuration,
  selectAreaId,
  (values, areaId) =>
    values.length > 0 && areaId !== null ? values.find(value => value.condition?.value === areaId)?.value : null,
);

export const selectMinDurationToPickupTime = createSelector(
  selectMinDurationToPickupTimeAreaValue,
  selectMinDurationToPickupTimeDefaultValue,
  (areaValue, defaultValue) => areaValue ?? defaultValue,
);

export const selectMaxDurationToPickupTime = createSelector(
  selectMaxDurationToPickupTimeAreaValue,
  selectMaxDurationToPickupTimeDefaultValue,
  (areaValue, defaultValue) => areaValue ?? defaultValue,
);

export const selectAllowContactDriverAfterTripDurationValue = createSelector(
  selectAllowContactDriverAfterTripDurationAreaValue,
  selectAllowContactDriverAfterTripDurationDefaultValue,
  (areaValue, defaultValue) => areaValue ?? defaultValue,
);

export const selectMinDate = createSelector(
  selectMinDurationToPickupTime,
  selectAreaTimeZoneId,
  (duration, areaTimeZoneId) => dateTimeNowAddDuration(areaTimeZoneId, duration),
);

export const selectMaxDate = createSelector(
  selectMaxDurationToPickupTime,
  selectAreaTimeZoneId,
  (duration, areaTimeZoneId) => dateTimeNowAddDuration(areaTimeZoneId, duration),
);

export const selectLocalDateNow = createSelector(selectAreaTimeZoneId, areaTimeZoneId =>
  toZonedTime(new Date(), areaTimeZoneId),
);

export const selectDisabledToCallDriver = createSelector(
  selectBookingAggregate,
  selectAllowContactDriverAfterTripDurationValue,
  selectLocalDateNow,
  selectAreaTimeZoneId,
  (aggregate, allowContactDriverAfterTripDuration, localNow, timeZoneId) => {
    if (!aggregate) {
      return true;
    }
    const status = getRideStatus(aggregate);

    if (status === RideStatus.COMPLETED) {
      const endDate = toZonedTime(aggregate.trip.ended_at, timeZoneId);
      endDate.setMilliseconds(endDate.getMilliseconds() + allowContactDriverAfterTripDuration);
      return localNow > endDate;
    }

    return !(status === RideStatus.ACCEPTED || status === RideStatus.IN_PROGRESS);
  },
);
