import { createActionGroup, props } from '@ngrx/store';
import { LocationVM, SuggestionsVM } from '../../../shared/models/location.vm';

import {
  Area,
  AreaPackage,
  BookingAggregate,
  BusinessNote,
  DriverTelemetry,
  Feature,
  Package,
  PlatformSettings,
  Quote,
  VehicleType,
} from '../../../shared/models/firestore.model';
import { CreateRideVM } from '../../../shared/models/rides.vm';
import { PaymentMethodTypeVM } from '../../../shared/models/business.vm';
import { NearbyDriver } from 'src/app/shared/models/b2b-web-api.model';

export const bookRideActions = createActionGroup({
  source: 'BookRide',
  events: {
    createFromInitialized: null,
    createFormDestroyed: null,
    resetFormFieldButtonClicked: null,
    templateSelected: props<{ templateId: string }>(),
    pickupSuggestionSelected: props<{ pickupLocation: SuggestionsVM; sessionToken: string }>(),
    pickupLocationChanged: props<{ pickupLocation: LocationVM }>(),
    pickupLocationTyped: props<{ query: string; sessionToken: string }>(),
    dropoffLocationTyped: props<{ query: string; sessionToken: string }>(),
    dropoffLocationSuggestionsLoaded: props<{ suggestions: SuggestionsVM[] }>(),
    pickupLocationSuggestionsLoaded: props<{ suggestions: SuggestionsVM[] }>(),
    dropoffSuggestionSelected: props<{ dropoffLocation: SuggestionsVM; sessionToken: string }>(),
    dropoffLocationChanged: props<{ dropoffLocation: LocationVM }>(),
    areaIdChanged: props<{ areaId: string }>(),
    areaPackage: props<{ areaPackage: AreaPackage }>(),
    packagesLoaded: props<{ packages: Package[] }>(),
    vehicleTypesLoaded: props<{ vehicleTypes: VehicleType[] }>(),
    featuresLoaded: props<{ features: Feature[] }>(),
    quoteRequested: null,
    packageIdChanged: props<{ packageId: string; vehicleTypeId: string; featureIds: string[] }>(),
    quoteChanged: props<{ quote: Quote }>(),
    quoteIdChanged: props<{ quoteId: string }>(),
    upcomingRidesLoaded: props<{ rides: BookingAggregate[] }>(),
    rideRequested: props<{ request: CreateRideVM }>(),
    rideRequestSucceeded: null,
    rideRequestFailed: null,
    platformSettingsLoaded: props<{ settings: PlatformSettings }>(),
    prebookRideSelected: null,
    pickupAtDateTimeEdited: null,
    pickupAtDateTimeChanged: props<{ dateTime: string }>(),
    paymentMethodTypeChanged: props<{ paymentMethodType: PaymentMethodTypeVM }>(),
    areasLoaded: props<{ areas: Area[] }>(),
    rideSelected: props<{ id: string }>(),
    rideDeselected: null,
    rideDetailsChanged: props<{ aggregate: BookingAggregate }>(),
    toggleRequestNowSwitched: props<{ isPrebooking: boolean }>(),
    sendTrackingLinkClicked: props<{ phoneNumber: string }>(),
    trackingLinkSent: null,
    sendTrackingLinkFailed: null,
    bookingCancelClicked: null,
    callDriverClicked: props<{ phoneNumber: string }>(),
    driverCalled: null,
    driverCallFailed: null,
    bookingCancelled: props<{ id: string }>(),
    bookingCancelFailed: null,
    businessNoteLoaded: props<{ note: BusinessNote }>(),
    nearbyDriversChanged: props<{ nearbyDrivers: NearbyDriver[]; eta: string }>(),
    allPackagesLoaded: props<{ packages: Package[] }>(),
    allVehicleTypesLoaded: props<{ vehicleTypes: VehicleType[] }>(),
    nearbyDriversTelemetriesChanged: props<{ nearbyDrivers: DriverTelemetry[] }>(),
  },
});

export const {
  createFromInitialized,
  createFormDestroyed,
  resetFormFieldButtonClicked,
  templateSelected,
  pickupSuggestionSelected,
  pickupLocationChanged,
  pickupLocationTyped,
  dropoffLocationTyped,
  dropoffLocationSuggestionsLoaded,
  pickupLocationSuggestionsLoaded,
  dropoffSuggestionSelected,
  dropoffLocationChanged,
  areaIdChanged,
  areaPackage,
  packagesLoaded,
  vehicleTypesLoaded,
  featuresLoaded,
  quoteRequested,
  packageIdChanged,
  quoteChanged,
  quoteIdChanged,
  upcomingRidesLoaded,
  rideRequested,
  rideRequestSucceeded,
  rideRequestFailed,
  platformSettingsLoaded,
  pickupAtDateTimeEdited,
  pickupAtDateTimeChanged,
  paymentMethodTypeChanged,
  areasLoaded,
  rideSelected,
  rideDeselected,
  rideDetailsChanged,
  toggleRequestNowSwitched,
  sendTrackingLinkClicked,
  trackingLinkSent,
  sendTrackingLinkFailed,
  callDriverClicked,
  driverCalled,
  driverCallFailed,
  bookingCancelClicked,
  bookingCancelled,
  bookingCancelFailed,
  businessNoteLoaded,
  nearbyDriversChanged,
  allPackagesLoaded,
  allVehicleTypesLoaded,
  nearbyDriversTelemetriesChanged,
} = bookRideActions;
