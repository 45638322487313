export enum PaymentMethodType {
    PAYMENT_METHOD_TYPE_UNDEFINED = 0,
    CARD = 1,
    VEHICLE_CASH = 2,
    VEHICLE_CARD = 3,
    INVOICE = 4,
    IN_PERSON = 5,
    PAYMENT_LINK = 6,
  }

export interface PaymentMethod {
    type: PaymentMethodType;
}
