import { createReducer, on } from '@ngrx/store';
import { BusinessAggregate } from 'src/app/shared/models/firestore.model';
import * as HomeActions from './home.actions';

export const homeFeatureKey = 'home';

export interface State {
  businessAggregate: BusinessAggregate;
}

export const initialState: State = {
  businessAggregate: undefined,
};

export const reducer = createReducer(
  initialState,

  on(HomeActions.businessAggregateChanged, (state, action) => ({
    ...state,
    businessAggregate: action.aggregate
  })),
);
