export class StringUtils {
  static areStringsEqual(str1?: string | null, str2?: string | null): boolean {
    return (str1 ?? '') === (str2 ?? '');
  }

  static isNullOrEmptyOrWhitespace(str: string | null | undefined): boolean {
    return !str || !str.trim();
  }

  static newLineFromFirebase(str?: string): string | null {
    if (!str) {
      return null;
    }
    return str.replace(/\\n/g, '\n');
  }

  static splitOnFirstComma(s: string, s2: string | null = null): [string, string] | null {
    if (!s) return null;
    if (s2 !== null) {
      return [s2, s];
    }
    const index = s.indexOf(',');
    if (index === -1) return [s, ''];
    return [s.substring(0, index), s.substring(index + 1)];
  }
}
