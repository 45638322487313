import { createAction, props } from '@ngrx/store';
import { RidesTableVM } from './rides.reducer';
import { RidesTab } from '../../../shared/models/rides.vm';
import { Area, BookingAggregate, PlatformSettings } from '../../../shared/models/firestore.model';

export const ridesComponentInit = createAction('[Rides] Rides component init');

export const ridesComponentDestroyed = createAction('[Rides] Rides component destroyed');

export const dataChanged = createAction(
  '[Rides] Rides data changed',
  props<{ tableVM: RidesTableVM; totalCount: number; areas: Area[] }>(),
);

export const filterChanged = createAction('[Rides] Filter changed', props<{ filter: { text: string } }>());
export const tabChanged = createAction('[Rides] Tab changed', props<{ tab: RidesTab }>());

export const pageEvent = createAction(
  '[Rides] Paging changed',
  props<{ pageSize: number; pageIndex: number; previousPageIndex?: number }>(),
);

export const rideSelected = createAction('[Rides] Ride selected', props<{ id: string }>());
export const rideDeselected = createAction('[Rides] Ride deselected');
export const rideDetailsChanged = createAction(
  '[Rides] Ride details changed',
  props<{ aggregate: BookingAggregate }>(),
);

export const bookingCancelClicked = createAction('[Rides] Booking cancel clicked');
export const bookingCancelled = createAction('[Rides] Booking cancelled', props<{ id: string }>());
export const bookingCancelFailed = createAction('[Rides] Booking cancel failed');

export const sendTrackingLinkClicked = createAction(
  '[Rides] Send tracking link clicked',
  props<{ phoneNumber: string }>(),
);
export const trackingLinkSent = createAction('[Rides] Tracking link sent');
export const sendTrackingLinkFailed = createAction('[Rides] Send tracking link failed');

export const callDriverClicked = createAction('[Rides] Call driver clicked', props<{ phoneNumber: string }>());
export const driverCalled = createAction('[Rides] Driver called');
export const driverCallFailed = createAction('[Rides] Driver call failed');

export const updatePickupTime = createAction('[Rides] Update pickup time', props<{ date: string }>());
export const pickupTimeUpdated = createAction('[Rides] Pickup time updated', props<{ id: string }>());
export const pickupTimeUpdateFailed = createAction('[Rides] Pickup time update failed');
export const pickupTimeUpdateCanceled = createAction('[Rides] Pickup time update canceled');
export const updateWithNewQuoteConfirmClicked = createAction('[Rides] Update with new quote confirm clicked');

export const areasLoaded = createAction('[Rides] Areas loaded', props<{ areas: Area[] }>());
export const areaIdChanged = createAction('[Rides] Area id changed', props<{ areaId: string }>());
export const platformSettingsLoaded = createAction(
  '[Rides] Platform settings loaded',
  props<{ settings: PlatformSettings }>(),
);
